import React from 'react';
import { navigate } from 'gatsby';

import { makeStyles } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';

type NewsItemProps = {
  chipLabel: string;
  title: string;
  url: string;
};

const useStyles = makeStyles({
  listChipStyle: {
    margin: `0 auto`,
  },
});

const NewsItem: React.VFC<NewsItemProps> = ({ chipLabel, title, url }) => {
  const handleClick = () => navigate(`/news/${url}`);
  const classes = useStyles();

  return (
    <ListItem button onClick={handleClick}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} sm={3} md={2}>
          <Chip
            color="primary"
            label={chipLabel}
            className={classes.listChipStyle}
          />
        </Grid>
        <Grid item xs={12} sm={9} md={10}>
          <ListItemText primary={title} />
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default NewsItem;
