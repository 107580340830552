import React from 'react';
import { PageProps } from 'gatsby';

import Layout from '@/components/others/Layout';
import FirstView from '@/components/Top/section/FirstView';
import News from '@/components/Top/section/News';
import Appeal from '@/components/Top/section/Appeal';
import CardBox from '@/components/Top/section/CardBox';
import TaxConsult from '@/components/Top/section/TaxConsult';
import BottomMenu from '@/components/common/section/BottomMenu';
import StaticSEO from '@/components/common/section/StaticSEO';

const Home: React.FC<PageProps> = () => (
  <Layout>
    <StaticSEO OgImageData="Greeting" top />
    <FirstView />
    <News />
    <Appeal />
    <CardBox />
    <TaxConsult />
    <BottomMenu />
  </Layout>
);

export default Home;
