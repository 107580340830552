import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import LinkCard from '../middle/LinkCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardsBox: {
      padding: `0 0 2rem`,
      background: `linear-gradient(180deg, ${theme.palette.background.default} 0%, ${theme.palette.background.default} 35%, ${theme.palette.primary.main} 65%, ${theme.palette.primary.main} 100%)`,
    },
    cardsWrapper: {
      width: `90%`,
      margin: `0 auto`,
    },
  }),
);

const CardBox: React.VFC = () => {
  const classes = useStyles();
  return (
    <section className={classes.cardsBox}>
      <Grid container className={classes.cardsWrapper} spacing={4}>
        <Grid item xs={12} sm={6}>
          <LinkCard
            linkName="よくあるご相談"
            linkImageName="Questions"
            path="questions"
            linkDesc={[
              `今まであったご相談の中で、`,
              `特に多かったものを`,
              `Q&A形式でまとめております。`,
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LinkCard
            linkName="お問い合わせ"
            linkImageName="Contact"
            path="contact"
            linkDesc={[
              `フォームでのお問い合わせはこちら。`,
              `もちろん、電話でのお問い合わせも`,
              `受け付けております。`,
            ]}
          />
        </Grid>
      </Grid>
    </section>
  );
};

export default CardBox;
