import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import OfficeImage from '../basic/OfficeImage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appealBox: {
      padding: `2rem 0`,
    },
    appealParaBox: {
      width: `90%`,
      margin: `1rem auto`,
      [theme.breakpoints.up(`lg`)]: {
        width: `auto`,
        margin: `auto`,
        marginRight: `max(10vw, calc((100vw - 1280px)/2))`,
      },
    },
    brAdjust: {
      display: `inline-block`,
    },
  }),
);

const Appeal: React.VFC = () => {
  const classes = useStyles();

  return (
    <section className={classes.appealBox}>
      <Grid container alignItems="center">
        <Grid xs={12} lg={7}>
          <OfficeImage />
        </Grid>
        <Grid xs={12} lg={5}>
          <div className={classes.appealParaBox}>
            <Typography variant="h2" align="right">
              市民のための税理士事務所
            </Typography>
            <Typography align="right">
              <span className={classes.brAdjust}>
                私たちは、50年以上にわたり
              </span>
              <br />
              <span className={classes.brAdjust}>富士見市鶴瀬とともに</span>
              <span className={classes.brAdjust}>歩んできました。</span>
              <br />
              <span className={classes.brAdjust}>
                個人の方から法人の方まで、
              </span>
              <span className={classes.brAdjust}>
                幅広い方々をお手伝いしております。
              </span>
              <br />
              <span className={classes.brAdjust}>少しでも気になることが</span>
              <span className={classes.brAdjust}>ございましたら、</span>
              <span className={classes.brAdjust}>お気軽にご連絡ください。</span>
            </Typography>
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default Appeal;
