import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  TaxConsultBox: {
    padding: `3rem 0`,
  },
  TaxConsultWrapper: {
    margin: `0 auto`,
    width: `90%`,
    maxWidth: `480px`,
  },
  brAdjust: {
    display: `inline-block`,
  },
});

const TaxConsult: React.VFC = () => {
  const bgImageData = useStaticQuery(
    graphql`
      query {
        imageData: file(relativePath: { eq: "TaxConsultBG.jpg" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `,
  );

  const image = getImage(bgImageData.imageData);
  const bgImage = convertToBgImage(image);

  const classes = useStyles();

  return (
    <BackgroundImage
      Tag="section"
      {...bgImage}
      backgroundColor="#fdfdfd"
      className={classes.TaxConsultBox}
    >
      <div className={classes.TaxConsultWrapper}>
        <Typography variant="h2" component="h3">
          税務相談のご案内
        </Typography>
        <Typography>
          <span className={classes.brAdjust}>私たち税理士は、</span>
          <span className={classes.brAdjust}>個別のご相談に限らず、</span>
          <br />
          <span className={classes.brAdjust}>税理士会から派遣され、</span>
          <br />
          <span className={classes.brAdjust}>富士見市の市⺠相談会にも、</span>
          <br />
          <span className={classes.brAdjust}>相談員として市⺠の</span>
          <span className={classes.brAdjust}>悩みに応じております。</span>
          <br />
          <span className={classes.brAdjust}>こちらが税務相談の情報です。</span>
        </Typography>
        <Typography>
          相談日:第4火曜日(祝日を除く)
          <br />
          時間:午後1時〜4時(1人30分)
          <br />
          場所:市役所2階 第3相談室
          <br />
          富士見市役所 電話:049-251-2711
        </Typography>
      </div>
    </BackgroundImage>
  );
};

export default TaxConsult;
