import React from 'react';
import { navigate } from 'gatsby';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import LinkCardMedia from '../basic/LinkCardMedia';

type LinkCardProps = {
  linkName: string;
  linkImageName:
    | 'Access'
    | 'Business'
    | 'Contact'
    | 'DefaultPost'
    | 'ExLink'
    | 'Greeting'
    | 'News'
    | 'OfficeInfo'
    | 'Questions'
    | 'Town';
  linkDesc: Array<string>;
  path: string;
};

const useStyles = makeStyles({
  card: {
    margin: `1rem auto`,
    maxWidth: 360,
  },
  media: {
    maxHeight: 240,
    maxWidth: 360,
  },
  brAdjust: {
    display: `inline-block`,
  },
});

const LinkCard: React.VFC<LinkCardProps> = ({
  linkName,
  linkImageName,
  linkDesc,
  path,
}) => {
  const handleClick = () => navigate(`/${path}`);

  const classes = useStyles();

  return (
    <Card elevation={4} className={classes.card}>
      <CardActionArea onClick={handleClick}>
        <CardMedia className={classes.media}>
          <LinkCardMedia fileName={linkImageName} />
        </CardMedia>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h3">
            {linkName}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {linkDesc.map((LinkDescItem) => (
              <span className={classes.brAdjust}>{LinkDescItem}</span>
            ))}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default LinkCard;
