import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { makeStyles, createStyles, Theme, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';

import NewsItem from '../middle/NewsItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    newsBox: {
      padding: `2rem 0`,
      background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 30%, ${theme.palette.background.default} 60%, ${theme.palette.background.default} 100%)`,
    },
    cardStyle: {
      width: `90%`,
      maxWidth: `960px`,
      margin: `0 auto`,
      padding: `1rem`,
    },
    sectionTitleStyle: {
      width: `90%`,
      maxWidth: `960px`,
      margin: `1rem auto`,
      color: `#ffffff`,
    },
    listChipStyle: {
      marginRight: `1rem`,
    },
  }),
);

const News: React.VFC = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulNewsPost(
        filter: { url: { ne: "dummy" } }
        sort: { order: DESC, fields: createdAt }
        limit: 4
      ) {
        edges {
          node {
            url
            title
            tag {
              title
            }
          }
        }
      }
    }
  `);

  const posts = data.allContentfulNewsPost.edges;

  const classes = useStyles();

  return (
    <section className={classes.newsBox}>
      <Typography variant="h2" className={classes.sectionTitleStyle}>
        お知らせ
      </Typography>
      <Card elevation={4} className={classes.cardStyle}>
        <List>
          {posts.length ? (
            posts.map((post) => (
              <NewsItem
                chipLabel={post.node.tag.title}
                title={post.node.title}
                url={post.node.url}
              />
            ))
          ) : (
            <Typography>まだお知らせはありません。</Typography>
          )}
        </List>
      </Card>
    </section>
  );
};

export default News;
