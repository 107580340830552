import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { orange } from '@material-ui/core/colors';

import FirstViewImage from '../basic/FirstViewImage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    FirstViewStyle: {
      backgroundColor: orange[500],
      padding: `64px 0 0`,
    },
    FirstViewTitleBox: {
      width: `90%`,
      margin: `1rem auto`,
      [theme.breakpoints.up(`lg`)]: {
        width: `auto`,
        margin: `auto`,
        marginLeft: `max(10vw, calc((100vw - 1280px)/2))`,
      },
    },
    brAdjust: {
      display: `inline-block`,
    },
  }),
);

const FirstView: React.VFC = () => {
  const classes = useStyles();

  return (
    <section className={classes.FirstViewStyle}>
      <Grid container direction="row-reverse" alignItems="center">
        <Grid xs={12} lg={7}>
          <FirstViewImage fileName="Greeting" />
        </Grid>
        <Grid xs={12} lg={5}>
          <div className={classes.FirstViewTitleBox}>
            <Typography variant="h1">
              <span className={classes.brAdjust}>私たちは、</span>
              <span className={classes.brAdjust}>富士見市鶴瀬で</span>
              <br />
              50年以上の実績をもつ
              <br />
              税理士事務所です
            </Typography>
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default FirstView;
