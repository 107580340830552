import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { makeStyles } from '@material-ui/core';

type CommonGatsbyImageProps = {
  fileName:
    | 'Access'
    | 'Business'
    | 'Contact'
    | 'DefaultPost'
    | 'ExLink'
    | 'Greeting'
    | 'News'
    | 'OfficeInfo'
    | 'Questions'
    | 'Town';
};

const useStyles = makeStyles({
  imageSize: {
    maxHeight: 240,
    maxWidth: 360,
  },
});

const LinkCardMedia: React.VFC<CommonGatsbyImageProps> = ({ fileName }) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile {
        nodes {
          relativePath
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  `);

  const image = data.images.nodes.find((n) =>
    n.relativePath.includes(fileName),
  );

  const classes = useStyles();

  return (
    <GatsbyImage
      image={getImage(image)}
      alt={fileName}
      objectPosition="50% 20%"
      className={classes.imageSize}
    />
  );
};

export default LinkCardMedia;
